
#histoframe {
    width : 1000px;
}

/* the absolute position is what will let us use the CSS left and top position to move the tooltip around later */
div.tooltip {	
    position: absolute;			
    text-align: center;			
    width: 300px;										
    padding: 2px;				
    font: 14px Helvetica, Roboto,sans-serif;	
    color: white;	
    background:  rgba(0,0,0,0.7);	
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;	
}

.datapoint {
    fill: rgb(32,150,243);
    fill-opacity: 0.5;
}

.datapoint:hover {
    fill: orange;
  }
  