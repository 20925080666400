
.logo {
    font: 40px Comfortaa;
    margin: 5px;

}

.logo a{
    color:black;
    text-decoration: none;
}
