.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgb(145,187,217);
    border-color: rgb(145,187,217);
}

.btn-secondary {
    color: black;
    background-color:#ededed;
    border-color: #ededed;
}

.btn-secondary:hover {
    color: #fff;
    background-color: rgb(145,187,217) ;
    border-color:rgb(145,187,217) ;
}