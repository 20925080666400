@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800);

#histoframe {
    width : 1000px;
}

/* the absolute position is what will let us use the CSS left and top position to move the tooltip around later */
div.tooltip {	
    position: absolute;			
    text-align: center;			
    width: 300px;										
    padding: 2px;				
    font: 14px Helvetica, Roboto,sans-serif;	
    color: white;	
    background:  rgba(0,0,0,0.7);	
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;	
}

.datapoint {
    fill: rgb(32,150,243);
    fill-opacity: 0.5;
}

.datapoint:hover {
    fill: orange;
  }
  
.bar {
    fill: rgb(150,207,151)
}

.bar:hover {
    fill:   rgb(145,187,217)
}

.bar .label {
    fill: white;
    font-size: 14px;
    pointer-events:none;
    /*text-anchor: end;*/
}

.blacklabel {
    fill: rgb(150,207,151);
    font-size: 10px;   
    pointer-events:none;
}

.axis--grid .tick--minor line {
    stroke-opacity: 0.5;
}

.segmented-control {
    display: table;
    width: 100%;
    margin: 2em 0;
    padding: 0;
    background : #ededed
}

.segmented-control__item {
    display: table-cell;
    margin: 0;
    padding: 0;
    width:100px;
    list-style-type: none;
}

.segmented-control__input {
    position: absolute;
    visibility: hidden;
}

.segmented-control__label {
    display: block;
    margin: 0 -1px -1px 0; /* -1px margin removes double-thickness borders between items */
    padding: 1em .25em;
    border: 1px solid white;
    text-align: center;
    cursor: pointer;
}

.segmented-control__label:hover {
    background:rgb(145,187,217);
}


.segmented-control__input:checked + .segmented-control__label {
    color: black    ;
    background: rgb(145,187,217);  /* to match the bursh with transparency */
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */

}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgb(145,187,217);
    border-color: rgb(145,187,217);
}

.btn-secondary {
    color: black;
    background-color:#ededed;
    border-color: #ededed;
}

.btn-secondary:hover {
    color: #fff;
    background-color: rgb(145,187,217) ;
    border-color:rgb(145,187,217) ;
}
.brush .selection  {
    fill:  rgb(145,187,217);
    fill-opacity: 0.3;
}

/* .brush .overlay {
    fill: lightgrey
} */

.brush .handle {
    fill:   rgb(145,187,217)
}

.axis--x .domain,
.axis--grid .tick line {
    stroke: white;
}

.axis--grid .domain {
    fill: #ededed;
    stroke: none;
    }

/* that's the tick on the yearbrush */
.tick{
    font-size : 12px;
}
.datapoint {
  stroke:black;
}

.tick line {
  stroke: #d5d5d5;
  opacity: 0.7;
}

.axis {
  color: grey;
}

/* SVG color for text is not cone with CSS color but CSS fill, don't ask me why */
.legend  {
  fill:#606060; 
}

/* .legend .label {
  color: green;
} */
.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    right: 0;
    top: 51px;
    width: 280px;
    background-color: #fff;
    border: 1px solid #aaa;
    z-index: 99;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }
  
  .react-autosuggest__section-container--first {
    border-top: 0;
  }
  
  .react-autosuggest__section-title {
    padding: 10px 0 0 10px;  
  }
  
.navbar-brand {
    font-size: 40px;
    /* color:white; */
    text-transform: lowercase;
    font-family: 'Comfortaa';
}   

.navbar-nav > li{
    font-size: 17px;
}


.homeText {
    font-size: 16px;
    color: #606060
}



.logo {
    font: 40px Comfortaa;
    margin: 5px;

}

.logo a{
    color:black;
    text-decoration: none;
}

