.datapoint {
  stroke:black;
}

.tick line {
  stroke: #d5d5d5;
  opacity: 0.7;
}

.axis {
  color: grey;
}

/* SVG color for text is not cone with CSS color but CSS fill, don't ask me why */
.legend  {
  fill:#606060; 
}

/* .legend .label {
  color: green;
} */