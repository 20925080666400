.bar {
    fill: rgb(150,207,151)
}

.bar:hover {
    fill:   rgb(145,187,217)
}

.bar .label {
    fill: white;
    font-size: 14px;
    pointer-events:none;
    /*text-anchor: end;*/
}

.blacklabel {
    fill: rgb(150,207,151);
    font-size: 10px;   
    pointer-events:none;
}

.axis--grid .tick--minor line {
    stroke-opacity: 0.5;
}