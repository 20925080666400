.navbar-brand {
    font-size: 40px;
    /* color:white; */
    text-transform: lowercase;
    font-family: 'Comfortaa';
}   

.navbar-nav > li{
    font-size: 17px;
}

