.segmented-control {
    display: table;
    width: 100%;
    margin: 2em 0;
    padding: 0;
    background : #ededed
}

.segmented-control__item {
    display: table-cell;
    margin: 0;
    padding: 0;
    width:100px;
    list-style-type: none;
}

.segmented-control__input {
    position: absolute;
    visibility: hidden;
}

.segmented-control__label {
    display: block;
    margin: 0 -1px -1px 0; /* -1px margin removes double-thickness borders between items */
    padding: 1em .25em;
    border: 1px solid white;
    text-align: center;
    cursor: pointer;
}

.segmented-control__label:hover {
    background:rgb(145,187,217);
}


.segmented-control__input:checked + .segmented-control__label {
    color: black    ;
    background: rgb(145,187,217);  /* to match the bursh with transparency */
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */

}
