.brush .selection  {
    fill:  rgb(145,187,217);
    fill-opacity: 0.3;
}

/* .brush .overlay {
    fill: lightgrey
} */

.brush .handle {
    fill:   rgb(145,187,217)
}

.axis--x .domain,
.axis--grid .tick line {
    stroke: white;
}

.axis--grid .domain {
    fill: #ededed;
    stroke: none;
    }

/* that's the tick on the yearbrush */
.tick{
    font-size : 12px;
}